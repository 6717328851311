import { StyleSheet } from "aphrodite";

export const colorThemes = {
  bg: ["white", "grey"],
  card: ["white"],
  text: ["white", "#777474", "#5C5959"],
  popup: ["whitesmoke", "grey"],
  button: ["#A4A4A4"],
  // textBlue:'#3e8de6'
  textBlue: "#4187ce",
};

export const textStylesV2 = StyleSheet.create({
  HeadlineL: {
    fontFamily: "Work Sans",
    fontWeight: 700,
    fontStyle: "bold",
    fontSize: "3vw",
    lineHeight: "3.3vw",
  },
  HotspotR: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontSize: "2.3vh",
  },
  HeadlineM: {
    fontFamily: "Work Sans",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "2.2vw",
    lineHeight: "2.6vw",
  },
  HeadlineS: {
    fontFamily: "Work Sans",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "1.7vw",
    lineHeight: "1.8vw",
  },
  BodyR: {
    fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "1.1vw",
    lineHeight: "1.25vw",
  },
  BodyS: {
    fontFamily: "Work Sans",
    fontWeight: 500,
    // fontSize: '0.8vw',
    // lineHeight: '0.9vw',
  },
  BodyCard: {
    fontFamily: "Work Sans",
    fontWeight: 300,
    fontSize: "0.9vw",
    lineHeight: "1.1vw",
  },
  BodyMisc: {
    fontFamily: "Work Sans",
    fontWeight: 500,
    fontSize: "1.3vw",
  },
  LabelNav: {
    fontFamily: "Work Sans",
    fontWeight: 500,
    fontSize: "2.4vh",
    marginTop: "0.4vh",
    // lineHeight: '24px',
    // padding: 8,
  },
  LabelL: {
    fontFamily: "Work Sans",
    fontWeight: 600,
    fontSize: "2.5vh",
    lineHeight: "1vh",
  },
  GodrejGreen: {
    // actually blue
    // color: '#7bb348',
    // color:'#3259D5'
    color: colorThemes.textBlue,
  },
  ActiveColor: {
    // color: '#7bb348',
    color: "#FEFEFE",
  },
  BasicColor: {
    color: "#FEFEFE",
  },
  DeactiveColor: {
    color: "#2C2D2C",
  },
});

export const buttonStylesV2 = StyleSheet.create({
  basicCTA: {
    height: "7vh",
    // width: '18vw',
    padding: "0vw 2vw 0vw 2vw",
    borderRadius: "1vh",
    outline: "none",
    border: "none",
    fontFamily: "Work Sans",
    fontWeight: 600,
    fontSize: "1.5vw",
    lineHeight: "28.8px",
    color: "#FEFEFE",
    ":hover": {
      outline: "solid 2px white",
      opacity: 0.9,
    },
    ":active": {
      transform: "scale(0.95)",
    },
  },
  buttonActive: {
    ":hover": {
      outline: "solid 2px white",
      opacity: 0.9,
      backgroundColor: "none",
    },
    ":active": {
      transform: "scale(0.95)",
    },
  },
  back: {
    backgroundColor: "#313131D1",
    color: "white",
    borderRadius: "4px",
    padding: "1vh",
    lineHeight: "4vh",
    boxSizing: "border-box",
  },
  gradientBluePink: {
    background:
      "linear-gradient(90deg, rgba(50,153,213,1) 0%, rgba(190,40,104,1) 100%)",
  },
});

export const textStyles = StyleSheet.create({
  para16: {
    fontFamily: "Helvetica",
    color: colorThemes.text,
    fontSize: 16,
    pointerEvents: "none",
  },
  heading28: {
    fontFamily: "Arial",
    color: colorThemes.text[0],
    fontWeight: 700,
    letterSpacing: "-3%",
    lineHeight: "33.6px",
    fontSize: 28,
    // pointerEvents:'none'
  },
  para20: {
    fontFamily: "Arial",
    color: colorThemes.text[0],
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "-1%",
    pointerEvents: "none",
  },
  nav20: {
    fontSize: "20px",
    fontFamily: "Arial",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "-3%",
    textAlign: "left",
    color: colorThemes.text[0],
    pointerEvents: "auto",
    cursor: "pointer",
  },
});
export const generalStyles = StyleSheet.create({
  button: {
    backgroundColor: colorThemes.button[0],
    boxSizing: "border-box",
    padding: "1.5% 2.2% 1.5% 2.2%",
    borderRadius: "5px",
    cursor: "pointer",
    border: "0px",
    // pointerEvents:'auto'
  },
  logoImageGodrej: {
    top: "2vh",
    left: "4vw",
    width: "4rem",
    position: "absolute",
    cursor: "pointer",
    zIndex: 9999999999,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoImageGEG: {
    top: "2vh",
    right: "3vw",
    width: "10rem",
    position: "absolute",
    cursor: "pointer",
    zIndex: 9999999999,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  line: {
    position: "absolute",
    width: "97%",
    transform: "translate(-50%,-50%)",
    height: "0.3%",
    backgroundColor: "white",
    top: "2.4%",
    left: "50%",
  },
  bottomLine: {
    top: "calc(100% - 2.6%)",
  },
});

export const springConfigs = {
  slow: {
    //800ms
    mass: 11.3,
    tension: 50,
    friction: 12,
    clamp: true,
  },
  slower: {
    //2000
    mass: 11.3,
    tension: 14,
    friction: 12,
    clamp: true,
  },
  evenSlower: {
    mass: 15.4,
    tension: 14,
    friction: 23,
    clamp: true,
  },
};
