// import logo from './logo.svg'
import "./App.css";
import { Canvas } from "@react-three/fiber";
import { Camera } from "./components/core/Camera";
import { RouteChecker } from "./components/Routing/RouteChecker";
import { Environment } from "./components/core/environment/Environment";
import { Suspense, useEffect, useRef, useState } from "react";
import { UIManager } from "./components/UI/UIManager";
import { WorkAround } from "./components/Routing/WorkAround";
import { ClickBlocker } from "./components/core/ClickBlocker";
import { LoadingScreen } from "./components/loading/LoadingScreen";
import { TrailingCursor } from "./components/Misc/TrailingCursor";
import { useProducts } from "./stores";
import axios from "axios";
import { baseUrl } from "./cms/cmsstore";
import { Leva } from "leva";
import CustomFade from "./components/core/CustomFade";
import { generalStyles } from "./appStyles";
import { css } from "aphrodite";
import ParallaxCamera from "./components/core/ParallaxCamera";
import { debugCamera, gaConfig } from "./constants";
import { Perf } from "r3f-perf";
import { Performance } from "./components/core/Performance";
import { NewLoadingScreen } from "./components/loading/NewLoadingScreen";
import { useHistory } from "react-router-dom";
import PortraitError from "./PortraitError/PortraitError";
// import { activateGA } from './google-analytics'

// activateGA(gaConfig.code)

function App() {
  const [mouseDown, setMouse] = useState(false);
  const productStore = useProducts();
  const ref = useRef();
  const history = useHistory();
  useEffect(() => {
    const fetch = async () => {
      let res = await axios.get(baseUrl);
      // res.data.map(x => (//console.log('tags',x.tags) ))
      // res.data.map(x => ({...x,tags:(x.tags && (x.tags+'').split(',')) }) )
      productStore.setProducts(res.data);
    };
    fetch();
  }, []);
  return (
    // <BrowserRouter>
    <>
      <PortraitError />
      <div
        ref={ref}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* <LoadingScreen /> */}
        <NewLoadingScreen />
        <UIManager />
        <RouteChecker />
        {/* <ClickBlocker /> */}
        <WorkAround />
        <Leva hidden={!debugCamera} />
        <div
          onMouseDown={() => setMouse(true)}
          onMouseUp={() => setMouse(false)}
          style={{
            position: "absolute",
            cursor: mouseDown ? "grabbing" : "grab",
            top: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Canvas
            performance={{ min: window.innerWidth < 900 ? 0.2 : 0.4 }}
            colorManagement
          >
            {/* <Perf position='top-left' /> */}
            {/* <Stats /> */}
            <Camera />
            <TrailingCursor />
            {/* <Plane/>  */}
            <Environment parentDiv={ref} />
            {/* <Performance /> */}
            {/* <CustomFade /> */}
            {/* <Perf position='top-left' /> */}
            {/* <ParallaxCamera /> */}
            {/* <ambientLight intensity={1}/> */}
          </Canvas>
          <img
            className={css(generalStyles.logoImageGodrej)}
            onClick={() => history.push("/")}
            src="./misc/godrej.png"
            alt="Godrej"
          />
          <img
            className={css(generalStyles.logoImageGEG)}
            src="./misc/geg.png"
            alt="Godrej Enterprises Group"
          />
        </div>
      </div>
    </>
    // </BrowserRouter>
  );
}

export default App;
